import React from "react";
import Link from "next/link";
import { AiOutlineSearch } from "react-icons/ai";

export default function Custom404() {
  return (
    <Link href="/search">
      <a>
        <div className="flex flex-col gap-2 items-center justify-center w-full h-[50vh]">
          <AiOutlineSearch className="text-gray-300 w-10 h-10" />
          <div className="text-gray-600 ">404</div>
          <div className="text-gray-600 ">Page Not Found</div>
          <div className="max-w-sm mx-5 text-center text-gray-400">
            You can search NFTs by the collection name, the contract address or
            by its market url.
          </div>
        </div>
      </a>
    </Link>
  );
}
